import { createSlice } from "@reduxjs/toolkit";
import {
  HASPAKEY,
  ISVALIDLICENSEKEY,
  MFGDATEKEY,
  OWNERSHIPKEY,
  PURDATEKEY,
  REGDATEKEY,
  THIRDPARTYKEY,
} from "../../utils/Constants";

let today = new Date().toISOString().slice(0, 10);

const CarInitialState = JSON.parse(
  sessionStorage.getItem("CarQueryParams")
) ?? {
  [MFGDATEKEY]: today,
  [PURDATEKEY]: today,
  [REGDATEKEY]: today,
  [OWNERSHIPKEY]: "individual",
  [ISVALIDLICENSEKEY]: true,
  [HASPAKEY]: true,
  [THIRDPARTYKEY]: false,
};

// Slice to store car details for searching a plan
const CarSlice = createSlice({
  name: "car",
  initialState: CarInitialState,
  reducers: {
    addDetail(state, action) {
      console.log(action);
      state[`${action.payload.key}`] = action.payload.value;
    },
  },
});

// Slice to keep track of current index of Caar details sections
export const CarDetailsIndexSlice = createSlice({
  name: "car_details_index",
  initialState: {
    currentIndex: 0,
  },
  reducers: {
    nextTab(state, action) {
      state.currentIndex++;
    },
    previousTab(state, action) {
      state.currentIndex--;
    },
    resetTab(state, action) {
      state.currentIndex = 0;
      sessionStorage.removeItem("CarQueryParams");
    },
  },
});

// Slice to fetch RTO data from api or localstorage
export const RtoDataSlice = createSlice({
  name: "rto_data",
  initialState: {
    docs: [],
    cities: [],
    codes: [],
  },
  reducers: {
    init(state, action) {
      // fetch api or load from local
      console.log(action.payload);
      state.cities = action.payload.cities;
      state.codes = action.payload.codes;
      state.docs = action.payload.docs;
    },
    getCities(state, action) {
      // filter cities from raw data
    },
    getRTOCodes(state, action) {
      // get RTO codes on the basis of city
    },
  },
});

export default CarSlice;
