import { Form, Input, Button } from "antd";
import { MailOutlined } from "@ant-design/icons";
import "./Auth.css";
import { useNavigate } from "react-router";

const Login = () => {
  const navigate = useNavigate();
  const nextHandler = (values) => {
    if (values.is_email_entered) {
      navigate("/insurance");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  
  return (
    <div className="box">
      <h1 className="auth-title">Amazing! Enter your email to log in</h1>
      <div className="email-input">
        <Form onFinish={nextHandler} onFinishFailed={onFinishFailed}>
          <Form.Item
            rules={[{ required: true, message: "Email is required" }]}
            name="is_email_entered"
          >
            <Input
              placeholder="Enter email"
              type="email"
              prefix={<MailOutlined />}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit" size="large">
            Next
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Login;
