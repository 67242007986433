import { Card, Button, Select } from "antd";
import "../../../pages/CarInsurancePage/InsuranceResult.css";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { SBRow, XsXlCol } from "../../common/containers";
import {
  HorizontalDivider,
  VerticalDivider,
} from "../../common/dividers";
const { Meta } = Card;
const { Option } = Select;

// Header component which will be displayed on the Insurance results page
// Contains details like city, model, Edit option, Quotation number, etc.
const CarSummaryHeader = ({carDetails}) => {
  const navigate = useNavigate();

  return (
    <div className="car-summary-header">
      <h1>Compare Car Insurance</h1>
      <div className="car-summary">
        <SBRow gutter={24} align="middle">
          <XsXlCol xl={4} >
            <Meta
              title="Quotation Number"
              description="ARBIMTRPC2022020412194284"
            />
          </XsXlCol>
          <XsXlCol xl={1}>
            <VerticalDivider />
            <HorizontalDivider />
          </XsXlCol>
          <XsXlCol xl={4}>
            <Button
              type="primary"
              icon={<EditOutlined />}
              style={{
                background: "#042342",
                borderColor: "#042342",
              }}
              onClick={(e) => {
                navigate(-1);
              }}
            >
              Edit vehicle details
            </Button>
            <HorizontalDivider />
          </XsXlCol>

          <XsXlCol xl={3}>
            <Meta title="RTO & City" description={carDetails.RTO ?? "None"} />
            <HorizontalDivider />
          </XsXlCol>

          <XsXlCol xl={5}>
            <Meta
              title="Vehicle Model"
              description={
                `${carDetails.maker} ${carDetails.model} ${carDetails.variant}` ??
                "HYUNDAI EON ERA (814 CC) - PETROL"
              }
            />
          </XsXlCol>
          <XsXlCol xl={1}>
            <VerticalDivider />
            <HorizontalDivider />
          </XsXlCol>
          <XsXlCol xl={6}>
            Insurance Companies :{" "}
            <Select placeholder="Select Companies" dropdownStyle={{}}>
              <Option value="digit">Digit</Option>
              <Option value="kotak">Kotak</Option>
              <Option value="hdfc">HDFC</Option>
            </Select>
          </XsXlCol>
        </SBRow>
      </div>
    </div>
  );
};

export default CarSummaryHeader;
