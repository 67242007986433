import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./Auth/AuthSlice";
import CarSlice, {
  CarDetailsIndexSlice,
  RtoDataSlice,
} from "./CarInsurance/CarSlice";

const store = configureStore({
  reducer: {
    car: CarSlice.reducer,
    carDetailsIndex: CarDetailsIndexSlice.reducer,
    rto_data: RtoDataSlice.reducer,
    auth: AuthSlice.reducer,
  },
});

// actions related to car insurance module
export const CarActions = CarSlice.actions;
export const CarIndexActions = CarDetailsIndexSlice.actions;
export const RtoDataActions = RtoDataSlice.actions;

// actiosn for authentication module
export const AuthActions = AuthSlice.actions;

export default store;
