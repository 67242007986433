import ProductImage from "../static/assets/product.png";
import HDFCImage from "../static/assets/hdfc.png";
export const ACTION_TABS = [
  "Policy Type",
  "RTO & City",
  "Make",
  "Model",
  "Variant",
  "Registration year",
  "Previous insurer",
  "Previous details",
];

export const CITIES = [
  "AHMDABAD",
  "HYDERBAD",
  "PUNE",
  "CHENNAI",
  "MUMBAI",
  "THANE",
];

export const CITYPLATES = {
  AHMDABAD: ["GJ 01 AHMD", "GJ 02 AHMD"],
  HYDERBAD: ["AP 01 AHMD", "AP 02 AHMD"],
  PUNE: ["MH 01 AHMD", "MH 02 AHMD"],
  CHENNAI: ["TL 01 AHMD", "TL 02 AHMD"],
  MUMBAI: ["MH 01 AHMD", "MH 02 AHMD"],
  THANE: ["MH 01 AHMD", "MH 02 AHMD"],
};

export const MAKERS = [
  "TATA",
  "AUDI",
  "BMW",
  "DATSUN",
  "FORD",
  "HONDA",
  "HYUNDAI",
];

export const MODELS = [
  "INDIGO",
  "Nano GenX",
  "SAFARI",
  "SUMO GOLD",
  "TIAGO",
  "ZEST",
  "HEXA",
  "TIGOR",
  "NEXON",
];

export const VARIANTS = [
  "XE (624 CC) - PETROL",
  "XM (624 CC) - PETROL",
  "XMA (624 CC) - PETROL",
  "XT (624 CC) - PETROL",
  "XTA (624 CC) - PETROL",
];

export const YEARS = [2022, 2021];

export const CARINSPRODUCTS = [
  {
    id: 0,
    name: "HDFC Ergo",
    cover: 364097,
    premium: 13340,
    NCB: 0,
    duration: 3,
    image: HDFCImage,
    properties: {
      feature: true,
      characteristics: true,
      comparison: true,
      advantage: true,
      disadvantage: false,
      lorem: false,
      ipsum: false,
    },
  },
  {
    id: 1,
    name: "Digit",
    cover: 330000,
    premium: 17961,
    NCB: 2,
    duration: 3,
    image: ProductImage,
    properties: {
      feature: true,
      characteristics: true,
      comparison: true,
      advantage: null,
      disadvantage: false,
      lorem: false,
      ipsum: false,
    },
  },
  {
    id: 1,
    name: "Digit",
    cover: 330000,
    premium: 17961,
    NCB: 2,
    duration: 3,
    image: ProductImage,
    properties: {
      feature: true,
      characteristics: true,
      comparison: true,
      advantage: null,
      disadvantage: false,
      lorem: false,
      ipsum: false,
    },
  },
  {
    id: 1,
    name: "Digit",
    cover: 330000,
    premium: 17961,
    NCB: 2,
    duration: 3,
    image: ProductImage,
    properties: {
      feature: true,
      characteristics: true,
      comparison: true,
      advantage: null,
      disadvantage: false,
      lorem: false,
      ipsum: false,
    },
  },
];
