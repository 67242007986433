import { Form } from "antd";
import "antd/dist/antd.less";
import "./index.css";
import "../../styles/global.css";
import { MAKERS } from "../../utils/DummyData";
import { CarActions } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { MAKERKEY } from "../../utils/Constants";
import SearchAndList, { ActionButtons } from "./SearchNListComponent";
import { CarIndexActions } from "../../store";

// tab no. 3
// Component to select maker company of car
const SelectMaker = () => {
  const dispatch = useDispatch();
  const curmaker = useSelector((state) => state.car[MAKERKEY]);
  
  const onFinish = (values) => {
    console.log("Success:", values);
    dispatch(
      CarActions.addDetail({
        key: MAKERKEY,
        value: values.maker,
      })
    );
    dispatch(CarIndexActions.nextTab());
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="center-box">
      <h1>Find your vehicle manufacturer</h1>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          maker: curmaker,
        }}
      >
        <SearchAndList
          placeholder="Search maker"
          name="maker"
          // onSearch={onSelect}
          // onItemSelect={onSelect}
          valueList={MAKERS}
          initialSelectedItem={curmaker}
          isImageTile={true}
        />
        <ActionButtons />
      </Form>
    </div>
  );
};

export default SelectMaker;
