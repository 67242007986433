import { Button } from "antd";
import "antd/dist/antd.less";
import { Theme } from "../../styles/Theme";

const ButtonStyle = {
  color: Theme.btnText,
  padding: "0.5em 2em",
  backgroundColor: Theme.mainColor,
  height: "max-content",
  width: "max-content",
  margin: "0em auto",
  border: `1px solid ${Theme.mainColor}`,
};
// Unused
const AntButton = (props) => {
  return (
    <Button
      onClick={props.onClick}
      style={ButtonStyle}
      disabled={props.disabled}
    >
      {props.title}
    </Button>
  );
};

// export default AntButton;

export const GrayButton = (props) => {
  return (
    <Button
      type="primary"
      style={{
        backgroundColor: Theme.inactiveColor,
        borderColor: Theme.inactiveColor,
      }}
      onClick={props.onClick}
    >{props.title}</Button>
  );
};
