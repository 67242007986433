import { Form } from "antd";
import "antd/dist/antd.less";
import "./index.css";
import "../../styles/global.css";
import { CarActions, CarIndexActions } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { PLATEKEY, RTOKEY } from "../../utils/Constants";
import SearchAndList, { ActionButtons } from "./SearchNListComponent";

// Tab no. 2
// Component to select RTO city of yours and also exact RTO plate number
// This component contains SearchAndList component with a sublist
const SelectRTO = () => {
  const RTO = useSelector((state) => state.car[RTOKEY]);
  const curplate = useSelector((state) => state.car[PLATEKEY]);
  const dispatch = useDispatch();
  const cities = useSelector((state) => state.rto_data.cities);
  const docs = useSelector((state) => state.rto_data.docs);

  const onChange = (e) => {
    // setTapplateindex(null);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    dispatch(
      CarActions.addDetail({
        key: RTOKEY,
        value: values.city,
      })
    );
    dispatch(
      CarActions.addDetail({
        key: PLATEKEY,
        value: values.plate,
      })
    );
    dispatch(CarIndexActions.nextTab());
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="center-box">
      <h1>What is your RTO and City?</h1>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          city: RTO,
          plate: curplate,
        }}
      >
        <SearchAndList
          placeholder="Enter city"
          name="city"
          sublistname="plate"
          onSearch={onChange}
          // onItemSelect={onSelect}
          valueList={cities}
          isImageTile={false}
          isSublist={true}
          subList={docs}
          sublistFilter={(city) => {
            return docs.filter((item) => {
              return item.rta_location.toLowerCase() === city;
            });
          }}
        />
        <ActionButtons />
      </Form>
    </div>
  );
};

export default SelectRTO;
