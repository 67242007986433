import { DatePicker, Select, Checkbox, Form, Button } from "antd";
import { GrayButton } from "../../common/button";
import "../../../pages/CarInsurancePage/CarPraposal.css";
import TextInputWithLabel from "../../common/input";
import { SBRow, XsXlCol } from "../../common/containers";
import { ThinHorizontalDivider } from "../../common/dividers";
const { Option } = Select;

// tab component get other details like Nominee or Appointee
const OtherDetails = (props) => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="otherdetails">
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        name="other_details"
      >
        <div className="nominee">
          <h1>Nominee Details</h1>
          <div>
            <SBRow>
              <XsXlCol xl={4}>
                <TextInputWithLabel label="Nominee Gender" required={true}>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Select gender"
                    optionFilterProp="children"
                  >
                    <Option value="female">Female</Option>
                    <Option value="male">Male</Option>
                    <Option value="other">Other</Option>
                  </Select>
                </TextInputWithLabel>
              </XsXlCol>
              <XsXlCol xl={4}>
                <TextInputWithLabel label="Relation" required={true}>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Relation"
                    optionFilterProp="children"
                  >
                    <Option value="wife">Wife</Option>
                    <Option value="child">Child</Option>
                  </Select>
                </TextInputWithLabel>
              </XsXlCol>
              <XsXlCol>
                <TextInputWithLabel
                  label="Nominee name"
                  placeholder="Nominee name"
                  required={true}
                ></TextInputWithLabel>
              </XsXlCol>
              <XsXlCol>
                <TextInputWithLabel
                  label="Nominee Date of Birth"
                  placeholder="date of birth"
                  required={true}
                >
                  <DatePicker style={{ width: "100%" }} />
                </TextInputWithLabel>
              </XsXlCol>
            </SBRow>
            <ThinHorizontalDivider />
          </div>
        </div>
        <div className="appointee">
          <h1>Appointee Details</h1>
          <div>
            <SBRow>
              <XsXlCol>
                <TextInputWithLabel label="Appointee Relation" required={true}>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Relation"
                    optionFilterProp="children"
                  >
                    <Option value="wife">Wife</Option>
                    <Option value="child">Child</Option>
                  </Select>
                </TextInputWithLabel>
              </XsXlCol>
              <XsXlCol>
                <TextInputWithLabel
                  label="Appointee name"
                  placeholder="Appointee name"
                  required={true}
                ></TextInputWithLabel>
              </XsXlCol>
              <XsXlCol>
                <TextInputWithLabel
                  label="Appointee Date of Birth"
                  placeholder="date of birth"
                  required={true}
                >
                  <DatePicker style={{ width: "100%" }} />
                </TextInputWithLabel>
              </XsXlCol>
            </SBRow>
            <ThinHorizontalDivider />
          </div>
        </div>
        <div className="ack">
          <h1> I understand that</h1>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please select all",
              },
            ]}
            name="acks"
          >
            <XsXlCol>
              <Checkbox name="ack1">
                My premium is derived on the basis of information filled by me.
              </Checkbox>
              <Checkbox name="ack2">
                The company shall have no liability under this insurance
                contract if it is found that any of my/our statement on
                particular or declaration in this proposal form or other
                documents are incorrect and / or untrue / false.
              </Checkbox>
              <Checkbox name="ack3">
                I hereby agree to the Terms and Conditions of the purchase of
                this policy.
              </Checkbox>
              <br />
              <Checkbox name="ack4">
                Receive service SMS and E-mail alerts.
              </Checkbox>
            </XsXlCol>
          </Form.Item>
        </div>
        <ThinHorizontalDivider />
        <SBRow>
          <XsXlCol xs={12}>
            <GrayButton
              title="Previous"
              onClick={() => {
                props.prevTab();
              }}
            />
          </XsXlCol>
          <XsXlCol
            xs={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button type="primary" htmlType="submit">
              Next
            </Button>
          </XsXlCol>
        </SBRow>
      </Form>
    </div>
  );
};

export default OtherDetails;
