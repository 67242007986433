import { Row, Form, Radio } from "antd";
import "antd/dist/antd.less";
import "./index.css";
import "../../styles/global.css";
import { YEARS } from "../../utils/DummyData";
import { CarActions, CarIndexActions } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { YEARKEY } from "../../utils/Constants";
import { ActionButtons } from "./SearchNListComponent";

// Tab no. 6
// Component to select vehicle registration year
const SelectYear = () => {
  const dispatch = useDispatch();
  const curyear = useSelector((state) => state.car[YEARKEY]);

  const onFinish = (values) => {
    console.log("Success:", values);
    dispatch(
      CarActions.addDetail({
        key: YEARKEY,
        value: values.year,
      })
    );
    dispatch(CarIndexActions.nextTab());
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="center-box">
      <h1>Select your vehicle registration year</h1>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          year: curyear,
        }}
      >
        <div className="list">
          <Row gutter={[16, 4]} justify="center">
            <Form.Item
              name="year"
              rules={[
                {
                  required: true,
                  message: "Please select a value",
                },
              ]}
            >
              <Radio.Group>
                {YEARS.map((year, index) => {
                  return (
                    <Radio.Button
                      style={{ margin: 5 }}
                      key={index}
                      value={year}
                    >
                      {year}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </Row>
        </div>
        <ActionButtons />
      </Form>
    </div>
  );
};

export default SelectYear;
