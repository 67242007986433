// This module contains various string and other constant values

import {
  CarInsImg,
  TravelInsImg,
  TwowheelInsImg,
  HealthInsImg,
  HomeInsImg,
  CommInsImg,
} from "../static";

export const VEHICLEOWNERSHIP = ["Individual", "Organization"];

export const TYPEKEY = "type";
export const MAKERKEY = "maker";
export const MODELKEY = "model";
export const VARIANTKEY = "variant";
export const RTOKEY = "RTO";
export const PLATEKEY = "plate";
export const YEARKEY = "year";
export const TERMKEY = "term";
export const MFGDATEKEY = "mfgdate";
export const PURDATEKEY = "purdate";
export const REGDATEKEY = "regdate";
export const OWNERSHIPKEY = "ownership";
export const ISVALIDLICENSEKEY = "isValidLicense";
export const HASPAKEY = "hasPA";
export const THIRDPARTYKEY = "tpp";

export const INSURANCEOPTIONSDATA = [
  {
    type: "Car",
    img: CarInsImg,
    tagline: "Protect your car and passengers",
    path: "car/details",
  },
  {
    type: "Two Wheeler",
    img: TwowheelInsImg,
    tagline: "Protect your two wheeler",
    path: "twowheeler/details",
  },
  {
    type: "Commercial",
    img: CommInsImg,
    tagline: "Insurance for commercial purpose",
    path: "commercial/details",
  },
  {
    type: "Travel",
    img: TravelInsImg,
    tagline: "Travel safe with your loved ones",
    path: "travel/details",
  },
  {
    type: "Health",
    img: HealthInsImg,
    tagline: "Protect the people you love the most",
    path: "health/details",
  },
  {
    type: "Home",
    img: HomeInsImg,
    tagline: "Protect your home and other stuff",
    path: "home/details",
  },
];
