import { Form } from "antd";
import "antd/dist/antd.less";
import "./index.css";
import "../../styles/global.css";
import { VARIANTS } from "../../utils/DummyData";
import { CarActions, CarIndexActions } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { VARIANTKEY } from "../../utils/Constants";
import SearchAndList, { ActionButtons } from "./SearchNListComponent";

// Tab no. 5
// Component to select model variant of your car
const SelectVariant = () => {
  const dispatch = useDispatch();
  const curvariant = useSelector((state) => state.car[VARIANTKEY]);

  const onFinish = (values) => {
    console.log("Success:", values);
    dispatch(
      CarActions.addDetail({
        key: VARIANTKEY,
        value: values.variant,
      })
    );
    dispatch(CarIndexActions.nextTab());
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="center-box">
      <h1>Select your vehicle variant</h1>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          variant: curvariant,
        }}
      >
        <SearchAndList
          placeholder="Search variant"
          valueList={VARIANTS}
          name="variant"
          initialSelectedItem={curvariant}
        />
        <ActionButtons />
      </Form>
    </div>
  );
};

export default SelectVariant;
