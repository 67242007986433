import "antd/dist/antd.less";
import { Form, Input } from "antd";
import "./common.css";

// Custom input component with label on the top
const TextInputWithLabel = (props) => {
  return (
    <div className="inputwithlabel">
      <div className="label">
        {props.label}
        {props.required ? "*" : ""}{" "}
      </div>
      <Form.Item
        // passing the input label as the name of item to reduce extra params of component
        name={props.name ?? `${props.label}`.replace(/ /g, "").toLowerCase()}
        rules={[
          {
            required: props.required,
            message: "Please fill the required fields",
          },
        ].concat(props.rules ?? [])}
      >
        {props.children ? (
          props.children
        ) : (
          <Input
            placeholder={props.placeholder}
            onChange={props.onChange ? props.onChange : () => {}}
          />
        )}
      </Form.Item>
    </div>
  );
};

export default TextInputWithLabel;
