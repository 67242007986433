import { Checkbox, Form, Select } from "antd";
import "antd/dist/antd.less";
import "./index.css";
import "../../styles/global.css";
import { CarActions, CarIndexActions } from "../../store";
import { useDispatch, useSelector } from "react-redux";
// import { useCallback, useState } from "react";
import TextInputWithLabel from "../common/input";
import { useCallback } from "react";
import { TERMKEY } from "../../utils/Constants";
import { ActionButtons } from "./SearchNListComponent";
const { Option } = Select;

// Tab no. 7
// Component to select policy term
const SelectPolicyTerm = () => {
  const dispatch = useDispatch();
  const term = useSelector((state) => state.car[TERMKEY]);

  const onChecked = useCallback((e) => {
    dispatch(CarActions.addDetail({ key: "tpp", value: e.target.checked }));
  }, []);

  const onFinish = (values) => {
    console.log("Success:", values);
    dispatch(
      CarActions.addDetail({
        key: TERMKEY,
        value: values.policy_term,
      })
    );
    dispatch(CarIndexActions.nextTab());
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="center-box">
      <h1>Previous Insurer & policy expiry</h1>
      <div
        className="outlined-box"
        style={{ maxWidth: "max-content", margin: "auto", marginTop: "1em" }}
      >
        <Checkbox onChange={onChecked}>Required only third party?</Checkbox>
      </div>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          policy_term: term,
        }}
      >
        <div
          style={{
            textAlign: "left",
            marginTop: "3em",
            minWidth: "max-content",
          }}
          className="input-wrapper"
        >
          <TextInputWithLabel
            name="policy_term"
            label="Select Policy term"
            required={true}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select policy term"
              optionFilterProp="children"
              defaultValue={term}
            >
              <Option value="1 Year OD + 3 Yr TP"> 1 Year OD + 3 Yr TP</Option>
              <Option value="1 Year OD + 4 Yr TP"> 1 Year OD + 4 Yr TP</Option>
            </Select>
          </TextInputWithLabel>
        </div>
        <ActionButtons />
      </Form>
    </div>
  );
};

export default SelectPolicyTerm;
