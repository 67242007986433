import PraposalSummaryHeader from "../../components/CarInsurance/Praposal/PraposalSummaryheader";
import DigitImage from "../../static/assets/icici.png";
import { Tabs } from "antd";
import "./CarPraposal.css";
import UserDetails from "../../components/CarInsurance/Praposal/UserDetails";
import VehicleDetails from "../../components/CarInsurance/Praposal/VehicleDetails";
import OtherDetails from "../../components/CarInsurance/Praposal/OtherDetails";
import { useState } from "react";
const { TabPane } = Tabs;

// Parent component to get User details, vehicle details and other details after pressing Mail quotation
const CarPraposal = () => {
  const [currenttab, setCurrenttab] = useState(1);

  const onTabChange = () => {};

  return (
    <div className="car-praposal-parent-div">
      <div className="car-praposal-summary-header">
        <PraposalSummaryHeader
          image={DigitImage}
          idv={52445}
          duration={3}
          model={"HYUNDAI EON ERA (814 CC) - PETROL"}
          quoatationNumber="ARBIMHDAFUVFWUEFHWUUFIUWFYB"
        />
      </div>
      <div className="car-praposal-details-section">
        <Tabs
          defaultActiveKey="1"
          onChange={onTabChange}
          centered={true}
          onTabClick={(e) => {
            // Conditionaly moving to previous tab
            if (e < currenttab) {
              setCurrenttab(e);
            }
          }}
          activeKey={`${currenttab}`}
        >
          <TabPane tab="User details" key="1">
            <UserDetails
              changeTab={() => {
                setCurrenttab(2);
              }}
            />
          </TabPane>
          <TabPane tab="Vehicle details" key="2">
            <VehicleDetails
              changeTab={() => {
                setCurrenttab(3);
              }}
              prevTab={() => {
                setCurrenttab(1);
              }}
            />
          </TabPane>
          <TabPane tab="Other details" key="3">
            <OtherDetails
              prevTab={() => {
                setCurrenttab(2);
              }}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default CarPraposal;
