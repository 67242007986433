import { DatePicker, Select, Radio, Form, Button } from "antd";
import "../../../pages/CarInsurancePage/CarPraposal.css";
import { ThinHorizontalDivider } from "../../common/dividers";
import { SBRow, XsXlCol } from "../../common/containers";
import TextInputWithLabel from "../../common/input";

const { Option } = Select;

// Tab component to get user details
const UserDetails = (props) => {
  const onFinish = (values) => {
    console.log("Success:", values);
    props.changeTab();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="userdetails">
      <Form name="userform" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <SBRow justify="space-between">
          <XsXlCol xl={2}>
            <TextInputWithLabel
              name="salutation"
              label="Salutation"
              required={true}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Select salutation"
                optionFilterProp="children"
              >
                <Option value="Dr.">Dr.</Option>
                <Option value="Mr.">Mr.</Option>
                <Option value="Mrs.">Mrs.</Option>
                <Option value="Ms.">Ms.</Option>
              </Select>
            </TextInputWithLabel>
          </XsXlCol>
          <XsXlCol xl={6}>
            <TextInputWithLabel
              label="First name"
              name="fname"
              placeholder="Enter first name"
              required={true}
            ></TextInputWithLabel>
          </XsXlCol>
          <XsXlCol>
            <TextInputWithLabel
              label="Middle name"
              name="mname"
              placeholder="Enter middle name"
              required={false}
            ></TextInputWithLabel>
          </XsXlCol>
          <XsXlCol>
            <TextInputWithLabel
              label="Last name"
              name="lname"
              placeholder="Enter last name"
              required={true}
            ></TextInputWithLabel>
          </XsXlCol>
        </SBRow>
        <ThinHorizontalDivider />
        <SBRow>
          <XsXlCol xl={4}>
            <TextInputWithLabel label="Gender" name="gender" required={true}>
              <Select
                style={{ width: "100%" }}
                placeholder="Select gender"
                optionFilterProp="children"
              >
                <Option value="female">Female</Option>
                <Option value="male">Male</Option>
                <Option value="other">Other</Option>
              </Select>
            </TextInputWithLabel>
          </XsXlCol>
          <XsXlCol xl={4}>
            <TextInputWithLabel
              label="Marital status"
              name="maritalStatus"
              required={true}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Marital status"
                optionFilterProp="children"
              >
                <Option value="single">Single</Option>
                <Option value="married">Married</Option>
              </Select>
            </TextInputWithLabel>
          </XsXlCol>
          <XsXlCol>
            <TextInputWithLabel
              label="Date of Birth"
              placeholder="date of birth"
              required={false}
            >
              <DatePicker style={{ width: "100%" }} />
            </TextInputWithLabel>
          </XsXlCol>
          <XsXlCol>
            <TextInputWithLabel
              label="Occupation"
              placeholder="Select occupation"
              required={true}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Select gender"
                optionFilterProp="children"
              >
                <Option value="media/sports">Media/sports</Option>
                <Option value="software/it">Software/IT</Option>
                <Option value="government">Government Job</Option>
                <Option value="other">Other</Option>
              </Select>
            </TextInputWithLabel>
          </XsXlCol>
        </SBRow>
        <ThinHorizontalDivider />
        <SBRow>
          <XsXlCol>
            <TextInputWithLabel
              label="Email"
              placeholder="Enter email"
              required={true}
            ></TextInputWithLabel>
          </XsXlCol>
          <XsXlCol>
            <TextInputWithLabel
              label="Mobile"
              placeholder="Enter mobile"
              required={true}
            ></TextInputWithLabel>
          </XsXlCol>
          <XsXlCol>
            <TextInputWithLabel
              label="Additional mobile number"
              placeholder="Enter alternate mobile"
              required={true}
            ></TextInputWithLabel>
          </XsXlCol>
        </SBRow>
        <ThinHorizontalDivider />
        <SBRow>
          <XsXlCol>
            <TextInputWithLabel
              label="Address 1"
              placeholder="Enter address"
              required={true}
            ></TextInputWithLabel>
          </XsXlCol>
          <XsXlCol>
            <TextInputWithLabel
              label="Address 2"
              placeholder="Enter address"
              required={true}
            ></TextInputWithLabel>
          </XsXlCol>
          <XsXlCol>
            <TextInputWithLabel
              label="Address 3"
              placeholder="Enter address"
              required={false}
            ></TextInputWithLabel>
          </XsXlCol>
        </SBRow>
        <ThinHorizontalDivider />
        <SBRow>
          <XsXlCol>
            <TextInputWithLabel
              label="State"
              placeholder="Select state"
              required={true}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                <Option value="1">Gujarat</Option>
                <Option value="2">Maharashtra</Option>
              </Select>
            </TextInputWithLabel>
          </XsXlCol>
          <XsXlCol>
            <TextInputWithLabel
              label="City"
              placeholder="Enter mobile"
              required={true}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                <Option value="1">Surat</Option>
                <Option value="2">Ahmedabad</Option>
              </Select>
            </TextInputWithLabel>
          </XsXlCol>
          <XsXlCol>
            <TextInputWithLabel
              label="Pin code"
              placeholder="XXXXXX"
              required={true}
            ></TextInputWithLabel>
          </XsXlCol>
        </SBRow>
        <ThinHorizontalDivider />
        <SBRow>
          <XsXlCol>
            <TextInputWithLabel
              label="Pancard"
              placeholder="Enter pancard no."
              required={true}
            ></TextInputWithLabel>
          </XsXlCol>
          <XsXlCol>
            <TextInputWithLabel
              label="GSTIN"
              placeholder="Enter GSTIN"
              required={false}
            ></TextInputWithLabel>
          </XsXlCol>
          <XsXlCol>
            <TextInputWithLabel
              label="Aadhar No."
              placeholder="xxxx-xxxx-xxxx-xxxx"
              required={true}
            ></TextInputWithLabel>
          </XsXlCol>
        </SBRow>
        <ThinHorizontalDivider />
        <div>
          <Form.Item
            label="Registration address is same as above?"
            name="same_address"
          >
            <Radio.Group defaultValue={true}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <ThinHorizontalDivider />
        <SBRow>
          <XsXlCol></XsXlCol>
          <XsXlCol style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="primary" htmlType="submit">
              Next
            </Button>
          </XsXlCol>
        </SBRow>
      </Form>
    </div>
  );
};

export default UserDetails;
