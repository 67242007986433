import { DatePicker, Button, Radio } from "antd";
import "antd/dist/antd.less";
import "./index.css";
import "../../styles/global.css";
import { useCallback } from "react";
import TextInputWithLabel from "../common/input";
import { CarActions } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  HASPAKEY,
  ISVALIDLICENSEKEY,
  MFGDATEKEY,
  OWNERSHIPKEY,
  PURDATEKEY,
  REGDATEKEY,
  VEHICLEOWNERSHIP,
} from "../../utils/Constants";
import { useNavigate } from "react-router";
import { PreviousButton } from "./SearchNListComponent";

let today = new Date().toISOString().slice(0, 10);

// Tab no. 8
// Component to get various dates and legal validations 
const SelectExpiryDate = () => {
  const Car = useSelector((state) => state.car);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Radio selection handlers
  const onChange = useCallback((e) => {
    if (e.target.name === "own") {
      dispatch(
        CarActions.addDetail({
          key: OWNERSHIPKEY,
          value: e.target.value,
        })
      );
    } else if (e.target.name === "license") {
      dispatch(
        CarActions.addDetail({ key: ISVALIDLICENSEKEY, value: e.target.value })
      );
    } else {
      dispatch(CarActions.addDetail({ key: HASPAKEY, value: e.target.value }));
    }
  }, []);

  // Date change handlers
  const onMfgdateChange = useCallback((date, datestring) => {
    dispatch(CarActions.addDetail({ key: MFGDATEKEY, value: datestring }));
  }, []);
  const onPurdateChange = useCallback((date, datestring) => {
    dispatch(CarActions.addDetail({ key: PURDATEKEY, value: datestring }));
  }, []);
  const onRegdateChange = useCallback((date, datestring) => {
    dispatch(CarActions.addDetail({ key: REGDATEKEY, value: datestring }));
  }, []);

  const getPlans = () => {
    sessionStorage.setItem("CarQueryParams", JSON.stringify(Car));
    navigate("/insurance/car/result");
  };

  return (
    <div className="center-box">
      <div className="dateinputs">
        <TextInputWithLabel label="Manufacture date">
          <DatePicker
            style={{ width: "90%" }}
            name="mfg"
            onChange={onMfgdateChange}
            placeholder={today}
          />
        </TextInputWithLabel>
        <TextInputWithLabel label="Date of first purchase">
          <DatePicker
            style={{ width: "90%" }}
            name="pur"
            placeholder={today}
            onChange={onPurdateChange}
          />
        </TextInputWithLabel>
        <TextInputWithLabel label="Registration date">
          <DatePicker
            style={{ width: "90%" }}
            placeholder={today}
            name="reg"
            onChange={onRegdateChange}
          />
        </TextInputWithLabel>
      </div>
      <div className="choices">
        <div className="choice">
          <TextInputWithLabel label="Customer Type">
            <div className="outlined-box">
              <p className="question">Vehicle owned by</p>
              <Radio.Group
                onChange={onChange}
                defaultValue={"individual"}
                name="own"
              >
                <Radio value="individual">Individual</Radio>
                <Radio value="organization">Organization</Radio>
              </Radio.Group>
            </div>
          </TextInputWithLabel>
        </div>
        <div className="choice">
          <TextInputWithLabel label="License validity">
            <div className="outlined-box">
              <p className="question">Do you have a valid driving license?</p>
              <Radio.Group
                onChange={onChange}
                name="license"
                defaultValue={true}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </div>
          </TextInputWithLabel>
        </div>
        <div className="choice">
          <TextInputWithLabel label="PA Cover">
            <div className="outlined-box">
              <p className="question">
                Do you already have a Personal Accident (PA) cover of
                Rs.1500000?
              </p>
              <Radio.Group onChange={onChange} name="pa" defaultValue={true}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </div>
          </TextInputWithLabel>
        </div>
      </div>
      <div className="actions">
        <PreviousButton />
        <div className="next-btn">
          <Button type="primary" onClick={getPlans}>
            Get Plans
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectExpiryDate;
