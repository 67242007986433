import { Form, Input, Button } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import "./Auth.css";
import { useNavigate } from "react-router";

const SignUp = () => {
  const navigate = useNavigate();
  const nextHandler = (values) => {
    if (values.is_email_entered  && values.is_password_entered) {
      navigate("/insurance");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="box">
      <h1 className="auth-title">Sign up for a new account</h1>
      <div className="email-input">
        <Form onFinish={nextHandler} onFinishFailed={onFinishFailed}>
          <Form.Item
            rules={[{ required: true, message: "Email is required" }]}
            name="is_email_entered"
          >
            <Input
              placeholder="Enter email"
              type="email"
              prefix={<MailOutlined />}
            />
          </Form.Item>
          <Form.Item
            name="is_password_entered"
            rules={[
              {
                required: true,
                message: "Password is required",
              },
            ]}
          >
            <Input type="password" placeholder="Enter password" prefix={<LockOutlined/>} />
          </Form.Item>
          <Button type="primary" htmlType="submit" size="large">
            Next
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default SignUp;
