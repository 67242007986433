import {
  SelectRTO,
  SelectMaker,
  SelectModel,
  SelectExpiryDate,
  SelectPolicyTerm,
  SelectVariant,
  SelectYear,
  SelectType,
} from "../../components/CarInsurance";
import "./CarInsurance.css";
import Lottie from "react-lottie";
import LoadingAnimData from "../../static/assets/lottie/loading.json";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { CarIndexActions } from "../../store";
import { fetchRtoData } from "../../store/CarInsurance/CarActions";

// List of different section to get car details from user
const TABS = [
  <SelectType />,
  <SelectRTO />,
  <SelectMaker />,
  <SelectModel />,
  <SelectVariant />,
  <SelectYear />,
  <SelectPolicyTerm />,
  <SelectExpiryDate />,
];

// Parent component for getting details of your car
const CarDetails = () => {
  const currentIndex = useSelector(
    (state) => state.carDetailsIndex.currentIndex
  );

  const cities = useSelector((state) => state.rto_data.cities);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchRtoData());
    }, 5000);
  }, [dispatch]);

  const nextTab = () => {
    if (currentIndex === 7) {
      console.log("View plans");
    } else if (currentIndex < 7) {
      dispatch(CarIndexActions.nextTab());
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="car-details">
      {cities === [] ? (
        <Lottie options={defaultOptions} />
      ) : (
        <>
          {/* Displaying car details section one-by-one according to currentIndex */}
          {TABS[currentIndex]}

          {/* Only for the first section of details */}
          <div className="actions">
            {currentIndex === 0 && (
              <div className="next-btn">
                <Button type="primary" onClick={nextTab}>
                  Get details
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CarDetails;
