import { Form } from "antd";
import "antd/dist/antd.less";
import "./index.css";
import "../../styles/global.css";
import { MODELS } from "../../utils/DummyData";
import { CarActions, CarIndexActions } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { MODELKEY } from "../../utils/Constants";
import SearchAndList, { ActionButtons } from "./SearchNListComponent";

// Tab no. 4
// Component to select Model name of car
const SelectModel = () => {
  const dispatch = useDispatch();
  const curmodel = useSelector((state) => state.car[MODELKEY]);

  const onFinish = (values) => {
    console.log("Success:", values);
    dispatch(
      CarActions.addDetail({
        key: MODELKEY,
        value: values.model,
      })
    );
    dispatch(CarIndexActions.nextTab());
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="center-box">
      <h1>Select your vehicle model</h1>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          model: curmodel,
        }}
      >
        <SearchAndList
          placeholder="Search model"
          name="model"
          // onSearch={onSelect}
          // onItemSelect={onSelect}
          valueList={MODELS}
          initialSelectedItem={curmodel}
        />
        <ActionButtons />
      </Form>
    </div>
  );
};

export default SelectModel;
