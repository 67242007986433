import { Select } from "antd";
import { Link } from "react-router-dom";
import LogoImage from "../../static/assets/light-logo.png";
import { Theme } from "../../styles/Theme";
import { SBRow, XsXlCol } from "../common/containers";
import {
  InstagramOutlined,
  FacebookOutlined,
  TwitterOutlined,
  YoutubeOutlined
} from "@ant-design/icons";
import "./Footer.css";
const { Option } = Select;

// Global footer component
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        <SBRow align="start">
          <XsXlCol xl={5}>
            <img src={LogoImage} alt="logo" className="footer-logo" />
          </XsXlCol>
          <XsXlCol xl={4} md={6}>
            <span className="footer-title">FEATURES</span>
            <div className="links-container">
              <Link to="/insurance/car/details">Car</Link>
              <Link to="/insurance/two-wheeler/details">Two wheeler</Link>
              <Link to="/insurance/commercial/details">Commercial</Link>
              <Link to="/insurance/travel/details">Travel</Link>
              <Link to="/insurance/health/details">Health</Link>
              <Link to="/insurance/home/details">Home</Link>
            </div>
          </XsXlCol>
          <XsXlCol xl={4} md={6}>
            <span className="footer-title">RESOURCES</span>
            <div className="links-container">
              <Link to="/">Home</Link>
              <Link to="/">About Us</Link>
              <Link to="/">Products</Link>
              <Link to="/">Policy PDF</Link>
              <Link to="/">Inspection Status</Link>
              <Link to="/">Contect Us</Link>
            </div>
          </XsXlCol>
          <XsXlCol xl={4} md={6}>
            <span className="footer-title">COMPANY</span>
            <div className="links-container">
              <Link to="/">Join the Team</Link>
              <Link to="/">Legal stuff</Link>
              <Link to="/">Privacy Pledge</Link>
              <Link to="/">Terms of Service</Link>
              <Link to="/">Accesibility</Link>
              <Link to="/">Review</Link>
            </div>
          </XsXlCol>
          <XsXlCol xl={6} md={6}>
            <span className="footer-title">REGION</span>
            <div className="links-container" style={{ width: "100%" }}>
              <Select
                style={{ width: "100%", backgroundColor: Theme.mainColor2 }}
                defaultValue="india"
              >
                <Option value="india">India</Option>
                <Option value="srilanka">Sri Lanka</Option>
                <Option value="nepal">Nepal</Option>
              </Select>
            </div>
            <div className="social-media">
              <span className="footer-title">FOLLOW US</span>
              <Link to="/">
                <InstagramOutlined />
              </Link>
              <Link to="/">
                <FacebookOutlined />
              </Link>
              <Link to="/">
                <TwitterOutlined />
              </Link>
              <Link to="/">
                <YoutubeOutlined />
              </Link>
            </div>
          </XsXlCol>
        </SBRow>
      </div>
    </div>
  );
};

export default Footer;
