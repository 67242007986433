import { Card } from "antd";
import { Avatar } from "antd";
import "../../pages/Homepage.css";

// Component to display user reviews on homepage
const ReviewCard = (props) => {
  return (
    <div className="review-card">
      <div className="reviewer">
        <Avatar
          src={
            <img
              src="https://joeschmoe.io/api/v1/random"
              style={{ width: "100%" }}
              alt="reviewer"
            />
          }
        />
        <Card.Meta
          title={props.title}
          description={props.subtitle}
          style={{ marginBottom: "1px" }}
        />
      </div>
      <div className="review-text">
        Always disappointed in how much insurance will fight against customer in
        a claim. Just had my first good experience with filing. Unbelievably
        quick and solid customer experience with @Fe Assurance.Truly lovely
        onboarding + customer support for a normally super frustrating service
      </div>
    </div>
  );
};

export default ReviewCard;
