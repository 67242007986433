import { Divider, Row, Col, Button } from "antd";
import CarSummaryHeader from "../../components/CarInsurance/Result/CarSummaryHeader";
import "./InsuranceResult.css";
import "../../components/common/common.css";
import { CARINSPRODUCTS } from "../../utils/DummyData";
import { LineOutlined, CheckOutlined } from "@ant-design/icons";
import { Theme } from "../../styles/Theme";
import FiltersSection from "../../components/CarInsurance/Result/FiltersSection";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { LoadingCard } from "../../components/common/card";

// Parent component to show various insurance plans from various companies
const CarInsuranceResults = () => {
  const CarDetails = JSON.parse(sessionStorage.getItem("CarQueryParams"));
  console.log(CarDetails);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="car-insurance-result">
      {/* Header to summarize car details */}
      <CarSummaryHeader carDetails={CarDetails} />
      <Divider />
      {/* Horizontally Responsive-Table for comparing plans */}
      {/* Column title of left side of table */}
      <div className="comparison">
        <div className="comparison-table-outer-div">
          <table className="comparison-table">
            <tbody>
              <tr>
                <th className="headcol">INSURANCE</th>
                {CARINSPRODUCTS.map((product, index) => {
                  return (
                    <td className="table-info-cell" key={index}>
                      <LoadingCard isLoading={isLoading} type="image">
                        <img
                          src={product.image}
                          alt={product.name}
                          width="100px"
                          height="80px"
                        />
                        <p className="product-name">{product.name}</p>
                      </LoadingCard>
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th className="headcol">COVER VALUE (IDV)</th>
                {CARINSPRODUCTS.map((product, index) => {
                  return (
                    <td className="table-info-cell" key={index}>
                      <LoadingCard isLoading={isLoading} type="text">
                        <p className="light-text">₹ {product.cover}</p>
                      </LoadingCard>
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th className="headcol">POLICY</th>
                {CARINSPRODUCTS.map((product, index) => {
                  return (
                    <td className="table-info-cell" key={index}>
                      <LoadingCard type="text" rows={4} isLoading={isLoading}>
                        <span>Private car package policy</span>
                        <Divider style={{ margin: "2px 0px" }} />
                        <Row justify="space-between">
                          <Col>NCB</Col>
                          <Col>₹ {product.NCB} %</Col>
                        </Row>
                        <Row justify="space-between">
                          <Col>Cover for</Col>
                          <Col>{product.duration} Year</Col>
                        </Row>
                      </LoadingCard>
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th className="headcol">PRICING</th>
                {CARINSPRODUCTS.map((product, index) => {
                  return (
                    <td className="table-info-cell" key={index}>
                      <LoadingCard isLoading={isLoading} type="text" rows={4}>
                        <span>Starts at</span>
                        <div className="large-hightlight-text">
                          ₹ {product.premium}
                        </div>
                        <div>1 Year OD + 3 Years TP</div>
                        <div className="action-btn">
                          <button className="blue-button-yellow-hover">
                            Plan Details
                          </button>
                        </div>
                      </LoadingCard>
                    </td>
                  );
                })}
              </tr>
              {/* Rendering the comparison check list section on the basis of properties available in data */}
              {/* Check the schema of data to get a better understanding */}
              {Object.keys(CARINSPRODUCTS[0].properties).map((key) => {
                return (
                  <tr className="table-prop-row" key={key}>
                    <th className="headcol">{key.toUpperCase()}</th>
                    {CARINSPRODUCTS.map((product, index) => {
                      return (
                        <td className="table-prop-cell" key={index}>
                          <LoadingCard type="text" isLoading={isLoading}>
                            {product.properties[key] ? (
                              <CheckOutlined
                                style={{ color: Theme.mainColor }}
                              />
                            ) : (
                              <LineOutlined />
                            )}
                          </LoadingCard>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="filters-section">
        <div className="filter-row">
          <FiltersSection />
        </div>
      </div>
      <div className="mail-btn">
        <Button
          onClick={() => {
            navigate("/insurance/car/praposal");
          }}
          type="primary"
        >
          Mail Quotation
        </Button>
      </div>
    </div>
  );
};

export default CarInsuranceResults;
