import { Row, Collapse } from "antd";
import PanelComponentsList from "./PanelComponents";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Theme } from "../../../styles/Theme";
import { XsXlCol } from "../../common/containers";
const { Panel } = Collapse;

// Bottom component for filters of insurance plans, displayed on the results page
// Wrapper component for all the filter panels
const FiltersSection = () => {
  return (
    <Row gutter={20} justify="center">
      {PanelComponentsList.map((panelcomp,index) => {
        return (
          <XsXlCol xl={4} key={index}>
            <Collapse
              defaultActiveKey={1}
              expandIconPosition="right"
              style={{
                background: Theme.mainColor,
                borderColor: Theme.mainColor,
              }}
              expandIcon={({ isActive }) =>
                !isActive ? <PlusOutlined /> : <MinusOutlined />
              }
            >
              <Panel header={panelcomp.title}>{panelcomp.comp}</Panel>
            </Collapse>
          </XsXlCol>
        );
      })}
    </Row>
  );
};

export default FiltersSection;
