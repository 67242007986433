import { Radio, Space, Button, Form } from "antd";
import "antd/dist/antd.less";
import "./Auth.css";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const AuthenticationWidget = () => {
  const navigate = useNavigate();
  const onFinish = (values) => {
    if (values.have_account) {
      navigate("login");
    } else {
      navigate("signup");
    }
  };
  return (
    <div className="box">
      <h1 className="auth-title">Do you already have an account?</h1>
      <Form onFinish={onFinish}>
        <Form.Item
          rules={[
            { required: true, message: "Selecting an option is required" },
          ]}
          name="have_account"
        >
          <Radio.Group className="login-step-radio-container">
            <Space direction="vertical">
              <Radio className="login-step-radio-wrapper" value={true}>
                Yes
              </Radio>
              <Radio className="login-step-radio-wrapper" value={false}>
                No
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Button type="primary" htmlType="submit" size="large">
          Next
        </Button>
      </Form>
      <div className="skip-or-login">
        <Link to="/insurance" className="underlined-link">
          Skip
        </Link>
        <Link to="login">Login?</Link>
      </div>
    </div>
  );
};

export default AuthenticationWidget;
