import { Col, Row } from "antd";

// Custom Antd:Row which justification of space-between
export const SBRow = (props) => {
  return (
    <Row gutter={24} justify="space-between" align={props.align}>
      {props.children}
    </Row>
  );
};

// Custom responsive Antd:Col
export const XsXlCol = (props) => {
  return (
    <Col
      xs={props.xs === undefined ? 24 : props.xs}
      xl={props.xl === undefined ? 8 : props.xl}
      md={props.md}
      flex={props.flex}
      style={props.style}
    >
      {props.children}
    </Col>
  );
};
