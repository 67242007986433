import { MainColor, InactiveColor, White, MainColor2 } from "./Colors";
import { lBFont, mBold } from "./Fonts";

export const Theme = {
  mainColor: MainColor,
  mainColor2: MainColor2,
  inactiveColor: InactiveColor,
  fzmBold: mBold,
  btnText: White,
  largeBoldFont: lBFont,
};
