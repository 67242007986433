import { Card, Skeleton } from "antd";
import "./common.css";

// const Card = (props) => {
//   return <div className="card">{props.children}</div>;
// };

// export default Card;

// Dynamic Antd:Card with loading skeleton of various types
export const LoadingCard = ({ isLoading, type, ...rest }) => {
  const renderLoader = () => {
    switch (type) {
      case "image":
        return <Skeleton.Image />;
      case "text":
        return (
          <Skeleton
            title={false}
            paragraph={{ rows: rest.rows ?? 1 }}
            active
            style={{ height: "100% !important" }}
          />
        );
      case "button":
        return <Skeleton.Button />;
      default:
        break;
    }
  };
  return (
    <Card style={{ padding: "0" }}>
      {isLoading ? renderLoader() : rest.children}
    </Card>
  );
};
