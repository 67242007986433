import { DatePicker, Button, Select, Radio, Form } from "antd";
import "../../../pages/CarInsurancePage/CarPraposal.css";
import { SBRow, XsXlCol } from "../../common/containers";
import { ThinHorizontalDivider } from "../../common/dividers";
import TextInputWithLabel from "../../common/input";
import { GrayButton } from "../../common/button";
const { Option } = Select;

// Tab component to get additional vehicle details
const VehicleDetails = (props) => {
  const onFinish = (values) => {
    console.log("Success:", values);
    props.changeTab();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="vehicledetails">
      <Form
        name="vehicle_form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <SBRow align="middle">
          <XsXlCol>
            <Form.Item
              label="Without Vehicle Number"
              name="without_vehicle_number"
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </XsXlCol>
          <XsXlCol>
            <TextInputWithLabel
              label="Vehicle number"
              placeholder="Ex. XX-88-XX-8888"
              required={true}
            ></TextInputWithLabel>
          </XsXlCol>
          <XsXlCol>
            <TextInputWithLabel label="Registration Date" required={false}>
              <DatePicker style={{ width: "100%" }} />
            </TextInputWithLabel>
          </XsXlCol>
        </SBRow>
        <ThinHorizontalDivider />
        <SBRow>
          <XsXlCol>
            <TextInputWithLabel label="Customer type" required={true}>
              <Select style={{ width: "100%" }} placeholder="Individual">
                <Option value="individual">Individual</Option>
                <Option value="organization">Organization</Option>
              </Select>
            </TextInputWithLabel>
          </XsXlCol>
          <XsXlCol>
            <TextInputWithLabel
              label="Engine number"
              placeholder="Enter engine name"
              required={true}
            ></TextInputWithLabel>
          </XsXlCol>
          <XsXlCol>
            <TextInputWithLabel
              label="Chasis number"
              placeholder="Chasis number"
              required={true}
            ></TextInputWithLabel>
          </XsXlCol>
        </SBRow>
        <ThinHorizontalDivider />
        <Form.Item label="Hypothection ?" name="is_hypothection">
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Has valid PUC ?" name="has_valid_puc">
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <ThinHorizontalDivider />

        <SBRow>
          <XsXlCol>
            <TextInputWithLabel
              label="Fastag number"
              placeholder="Fastag number"
              required={true}
            ></TextInputWithLabel>
          </XsXlCol>
        </SBRow>
        <SBRow>
          <XsXlCol xs={12}>
            <GrayButton
              title="Previous"
              onClick={() => {
                props.prevTab();
              }}
            />
          </XsXlCol>
          <XsXlCol
            xs={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button type="primary" htmlType="submit">
              Next
            </Button>
          </XsXlCol>
        </SBRow>
      </Form>
    </div>
  );
};

export default VehicleDetails;
