import { createSlice } from "@reduxjs/toolkit";

// Redux slice for user-authentication
const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
  },
  reducers: {
    checkauth(state, action) {},
    login(state, action) {},
    signup(state, action) {},
    logout(state, action) {},
  },
});

export default AuthSlice;