import { RtoDataActions } from "..";

export const fetchRtoData = () => {
  return async (dispatch) => {
    const fetchdata = async () => {
      console.log("calling api");
      const response = await fetch(
        "https://aarviinsurance.hastag.cloud/api/car-insurance/v1/list_rta?limit=1281",
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        throw Error("fetching failed!");
      }
      const data = await response.json();
      return data;
    };

    try {
      const cities = JSON.parse(localStorage.getItem("cities")) ?? [];
      const codes = JSON.parse(localStorage.getItem("codes")) ?? [];
      var docs = JSON.parse(localStorage.getItem("docs")) ?? [];
      if (cities.length === 0 || codes.length === 0) {
        const rtodata = await fetchdata();
        docs = rtodata.docs;
        docs.forEach((item) => {
          cities.push(item.rta_location);
          codes.push(item.rta_code);
        });
        localStorage.setItem("docs", JSON.stringify(docs));
        localStorage.setItem("cities", JSON.stringify(cities));
        localStorage.setItem("codes", JSON.stringify(codes));
      }
      dispatch(RtoDataActions.init({ cities, codes, docs }));
    } catch (error) {
      console.log(error);
    }
  };
};
