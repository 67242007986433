import { Card } from "antd";
import { useCallback } from "react";
import "../../pages/Homepage.css";
import { useNavigate } from "react-router";

// Card component to display offered products in FeAssurance on the homepage
const InsuranceCard = (props) => {
  const navigate = useNavigate();
  const onClickhandler = useCallback((e) => {
    navigate(`/insurance/${props.path}`.toLowerCase());
  }, []);
  return (
    <Card
      className="ins-card"
      cover={
        <img
          src={props.img}
          alt={props.type}
          style={{
            width: "70px",
            height: "70px",
            objectFit: "contain",
            paddingTop: "1em",
          }}
        />
      }
    >
      <div className="ins-card-content">
        <div className="ins-type">{props.type}</div>
        <div className="ins-subtitle">Insurance</div>
        <div className="ins-tagline">{props.tagline}</div>
      </div>
      <button className="price-btn" onClick={onClickhandler}>
        Check Prices
      </button>
    </Card>
  );
};

export default InsuranceCard;
