import { Radio, Checkbox, Space } from "antd";
import { useState } from "react";

// This module contains components which will be used as the filters for insurance plans
export const IDV = () => {
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  return (
    <div className="idv">
      <h3>Set Your IDV</h3>
      <Radio.Group onChange={onChange} value={value}>
        <Space direction="vertical">
          <Radio value="auto">Automatic</Radio>
          <Radio value="set">Set IDV</Radio>
        </Space>
      </Radio.Group>
    </div>
  );
};

export const AdditionalCover = () => {
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
  };
  return (
    <div className="column">
      <Checkbox onChange={onChange}>Electric Accesary</Checkbox>
      <Checkbox onChange={onChange}>Non-electric Accesary?</Checkbox>
      <Checkbox onChange={onChange}>Bi-fuel kit?</Checkbox>
      <Checkbox onChange={onChange}>PA covered for paid driver?</Checkbox>
    </div>
  );
};

export const Discount = () => {
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
  };
  return (
    <div className="column">
      <Checkbox onChange={onChange}>Voluntary access</Checkbox>
      <Checkbox onChange={onChange}>Antithefy device</Checkbox>
      <Checkbox onChange={onChange}>Member of AAI?</Checkbox>
      <Checkbox onChange={onChange}>Modified for handicap?</Checkbox>
    </div>
  );
};

// TODO: Changing date dynamically according to Car state
export const OtherDetails = () => {
  return (
    <div className="column">
      <p>
        Manufacture date : <span>17-Feb-2021</span>
      </p>
      <p>
        Purchase date : <span>17-Feb-2021</span>
      </p>
      <p>
        Registration date : <span>17-Feb-2021</span>
      </p>
    </div>
  );
};

export const Addon = () => {
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);

  const options = [
    "Emergency cover",
    "Zero dep cover",
    "Consumable",
    "Tyre cover",
    "NCB Protection",
    "Engine Protector",
    "Return to invoice",
    "Loss of key",
  ];
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
  };
  return (
    <div className="column">
      <div
        className="ant-checkbox-group"
        style={{ display: "flex", flexDirection: "column", marginRight: 10 }}
      >
        {options.map((label) => (
          <Checkbox key={label} label={label} value={label}>
            {label}
          </Checkbox>
        ))}
      </div>
    </div>
  );
};

const PanelComponentsList = [
  {
    title: "Change IDV",
    comp: <IDV />,
  },
  {
    title: "Additional cover",
    comp: <AdditionalCover />,
  },
  {
    title: "Discounts",
    comp: <Discount />,
  },
  {
    title: "Other details",
    comp: <OtherDetails />,
  },
  {
    title: "Add-on",
    comp: <Addon />,
  },
];

export default PanelComponentsList;
