/* eslint-disable react-hooks/exhaustive-deps */
import { Tabs } from "antd";
import "antd/dist/antd.less";
import { Theme } from "../../styles/Theme";
import "./index.css";
import { CarActions } from "../../store";
import { useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import { TYPEKEY } from "../../utils/Constants";
import Lottie from "react-lottie";
import * as CarAnimData from "../../static/assets/lottie/Car-Animation.json";

const { TabPane } = Tabs;

const TabBarStyle = {
  color: Theme.inactiveColor,
  fontWeight: "500",
};

const TabTitles = ["New", "Renew", "Old"];

// tab no. 1
// Component to Select type of insurance
const SelectType = () => {
  const dispatch = useDispatch();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: CarAnimData,
    rendererSettings: {
      preserveAspectRatio: "xMidyMid slice",
    },
  };

  useEffect(() => {
    dispatch(CarActions.addDetail({ value: "New", key: TYPEKEY }));
  }, []);

  const callback = useCallback((key) => {
    dispatch(
      CarActions.addDetail({ value: `${TabTitles[key - 1]}`, key: TYPEKEY })
    );
  }, []);

  return (
    <div className="center-box">
      <div className="anim">
        <Lottie options={defaultOptions} />
      </div>
      <h1>Car Insurance</h1>
      <h2 className="subheading">
        This information will let us know basic information about your car
      </h2>
      <div className="tab-wrapper">
        <Tabs
          defaultActiveKey="1"
          onChange={callback}
          centered={true}
          tabBarStyle={TabBarStyle}
        >
          <TabPane tab="New" key="1">
            <div></div>
          </TabPane>
          <TabPane tab="Renew" key="2">
            <div></div>
          </TabPane>
          <TabPane tab="Already have quotation?" key="3">
            <div></div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default SelectType;
