import "./Homepage.css";
import { useNavigate } from "react-router";
import LOGO from "../static/assets/logo.png";
import { Button } from "antd";
import Lottie from "react-lottie";
import InsuranceCard from "../components/Homepage/InsuranceCard";
import { InsuranceAnimData } from "../static";
import { INSURANCEOPTIONSDATA } from "../utils/Constants";
import FeaturedImage from "../static/assets/featured.png";
import AckoImage from "../static/assets/acko.png";
import AdityaImage from "../static/assets/aditya.png";
import ApoloImage from "../static/assets/apolo.png";
import BajajImage from "../static/assets/bajaj.png";
import BhartiImage from "../static/assets/bharti.png";
import { StarFilled } from "@ant-design/icons";
import { Theme } from "../styles/Theme";
import ReviewCard from "../components/Homepage/ReviewCard";
import DataImage from "../static/assets/switch-data.png";
import Footer from "../components/Homepage/Footer";

const Homepage = () => {
  const navigate = useNavigate();
  const getQuote = () => {
    navigate("/auth/");
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: InsuranceAnimData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="home">
      <img src={LOGO} alt="Logo" width={"200px"} className="logo-img" />
      <h1>
        Get the best
        <br />
        Insurance Deal
      </h1>
      <h2>Let's help you find your next best Insurance</h2>
      <div className="btn">
        <Button type="primary" onClick={getQuote}>
          Get a Quote
        </Button>
      </div>
      <div className="anim">
        <Lottie options={defaultOptions} />
      </div>
      <div className="reviews-ratings-wrapper">
        <div className="featured-image">
          <img src={FeaturedImage} alt="featured" width={"100%"} />
        </div>
        <h1>5 Star Insurance Company</h1>
        <p>
          Fe Assurance has earned 54 stars in the Google Paystore and App store,
          and is also top-rated by Supermoney, Clearsurance, and others
        </p>
        <div className="ratings">
          <StarFilled style={{ color: Theme.mainColor, fontSize: "70px" }} />
          <StarFilled style={{ color: Theme.mainColor, fontSize: "70px" }} />
          <StarFilled style={{ color: Theme.mainColor, fontSize: "70px" }} />
          <StarFilled style={{ color: Theme.mainColor, fontSize: "70px" }} />
          <StarFilled style={{ color: Theme.mainColor, fontSize: "70px" }} />
        </div>
        <div className="reviews-section">
          {[...Array(5)].map((x, i) => (
            <ReviewCard title="Robert Frost" subtitle="CEO Awaking dreams" />
          ))}
        </div>
      </div>
      <div className="products-section">
        <h1>Incredible Prices. Monthly Subscription.</h1>
        <h1>Bundle Discounts.</h1>
        <div className="insurance-options-wrapper">
          {INSURANCEOPTIONSDATA.map((item, index) => {
            return (
              <InsuranceCard
                type={item.type}
                img={item.img}
                path={item.path}
                tagline={item.tagline}
                key={index}
              />
            );
          })}
        </div>
      </div>
      <div className="switching-data-section">
        <h1>Already insured? We’ll help you switch!</h1>
        <p>People left these insurance companies to join Fe Assurance</p>
        <img src={DataImage} alt="data" />
        <div>
          <Button type="primary">Check Prices</Button>
        </div>
      </div>
      <div className="insurance-partners-section">
        <h1>Insurance Partners</h1>
        <div className="insurance-partners">
          <img src={BhartiImage} alt="bharti" />
          <img src={BajajImage} alt="bajaj" />
          <img src={AdityaImage} alt="aditya" />
          <img src={ApoloImage} alt="apolo" />
          <img src={AckoImage} alt="acko" />
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Homepage;
