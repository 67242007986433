import { Form, Radio, Button, Row } from "antd";
import "./Homepage.css";
import CarInsImg from "../static/assets/sedan.png";
import TwowheelInsImg from "../static/assets/delivery-man.png";
import CommInsImg from "../static/assets/delivery-truck.png";
import TravelInsImg from "../static/assets/plane.png";
import HealthInsImg from "../static/assets/heartbeat.png";
import HomeInsImg from "../static/assets/home.png";
import { useNavigate } from "react-router";

// Component to select any insurance product after authentication
const InsuranceWidget = () => {
  const navigate = useNavigate();
  return (
    <div className="box">
      <h1>Which insurance would you like to buy?</h1>
      <div className="insurance-choices">
        <Form
          onFinish={(values) => {
            console.log(values);
            switch (values.insurance_type) {
              case "car":
                navigate("car/details");
                break;
              default:
                break;
            }
          }}
        >
          <Form.Item
            rules={[
              { required: true, message: "Selecting an option is required" },
            ]}
            name="insurance_type"
          >
            <Radio.Group>
              <Row justify="space-between" className="ins-choice-wrapper">
                <div className="ins-choice-item">
                  <img src={CarInsImg} alt="car insurance" />
                  <Radio value="car">Car</Radio>
                </div>
                <div className="ins-choice-item">
                  <img
                    src={TwowheelInsImg}
                    alt="twowheel insurance"
                    width={"100px"}
                  />
                  <Radio value="twow">Two wheeler</Radio>
                </div>
                <div className="ins-choice-item">
                  <img
                    src={CommInsImg}
                    alt="commercial insurance"
                    width={"100px"}
                  />
                  <Radio value="comm">Commercial</Radio>
                </div>
                <div className="ins-choice-item">
                  <img
                    src={TravelInsImg}
                    alt="travel insurance"
                    width={"100px"}
                  />
                  <Radio value="travel">Travel</Radio>
                </div>
                <div className="ins-choice-item">
                  <img
                    src={HealthInsImg}
                    width={"100px"}
                    alt="health insurance"
                  />
                  <Radio value="health">Health</Radio>
                </div>
                <div className="ins-choice-item">
                  <img src={HomeInsImg} width={"100px"} alt="home insurance" />
                  <Radio value="home">Home</Radio>
                </div>
              </Row>
            </Radio.Group>
          </Form.Item>
          <Button type="primary" htmlType="submit" onClick={() => {}}>
            Next
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default InsuranceWidget;
