import { HorizontalDivider, VerticalDivider } from "../../common/dividers";
import { Row, Card } from "antd";
import { XsXlCol } from "../../common/containers";
import { Theme } from "../../../styles/Theme";
const { Meta } = Card;

// Header component displayed on the Praposal page
// Contains information regarding insurance plan
const PraposalSummaryHeader = (props) => {
  return (
    <Row justify="space-between" align="middle">
      <XsXlCol xl={3}>
        <img src={props.image} className="header-product-image" alt="icici" />
      </XsXlCol>
      <VerticalDivider />
      <HorizontalDivider />
      <XsXlCol xl={3}>
        <Row justify="space-between">
          <Meta title="IDV" description={`₹ ${props.idv}`} />
          <Meta title="Cover for" description={`${props.duration} Years`} />
        </Row>
      </XsXlCol>
      <HorizontalDivider />
      <VerticalDivider />
      <XsXlCol xl={5}>
        <Row justify="space-between">
          <Meta title="Policy Start Date" description="03-Mar-2022" />
          <Meta title="Policy Start Date" description="03-Mar-2022" />
        </Row>
      </XsXlCol>
      <HorizontalDivider />
      <VerticalDivider />
      <XsXlCol xl={7}>
        <Row justify="space-between">
          <Meta
            title="Quoatation Number"
            description={props.quoatationNumber}
          />
          <Meta title="Vehicle Model" description={props.model} />
        </Row>
      </XsXlCol>
      <HorizontalDivider />
      <VerticalDivider />
      <XsXlCol xl={2}>
        <h3>PREMIUM</h3>
        <h3 style={Theme.largeBoldFont}>₹ 12588</h3>
      </XsXlCol>
    </Row>
  );
};

export default PraposalSummaryHeader;
