import { Divider } from "antd";
import { Theme } from "../../styles/Theme";
import "./common.css";

// Custom responsive Antd:Divider
export const VerticalDivider = () => {
  return (
    <div style={{ height: "100px" }} className="vertical-divider-wrapper">
      <Divider
        type="vertical"
        style={{
          height: "100%",
          width: "5px",
          borderColor: Theme.inactiveColor,
        }}
      />
    </div>
  );
};

export const HorizontalDivider = () => {
  return (
    <div className="horizontal-divider-wrapper">
      <Divider
        type="horizontal"
        style={{
          borderColor: Theme.inactiveColor,
          margin: "10px 0px"
        }}
      />
    </div>
  );
};

export const ThinHorizontalDivider = () => {
  return <Divider style={{ margin: "10px 0px" }} />;
};
