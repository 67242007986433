import { Input, Row, Button, Radio, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState } from "react";
import "antd/dist/antd.less";
import "./index.css";
import "../../styles/global.css";
import MakerImage from "../../static/assets/tata.png";
import { useDispatch } from "react-redux";
import { CarIndexActions } from "../../store";

// Custom component to take input, search and display a list of tiles/Radio buttons from results

const SearchAndList = ({
  placeholder, // Input placeholder text
  prefix, // preffix icon for Input
  size, //Size of Input
  valueList, // List of items which will be available to select
  // onItemSelect,
  // initialSelectedItem,
  isImageTile, // Toggler for whether the Radio/tile contains any image or not
  name, // name property for Form validations
  isSublist, // Toggler for whether the component will have a sublist selection or not
  subList, // List of items which will be available to select in sublist
  sublistname, // name property for sublist form validations
  sublistFilter, // filter operation function on sublist
}) => {
  const searchprefix = <SearchOutlined onClick={() => {}} />;

  // State variable for Input value
  const [inputvalue, setInputvalue] = useState(null);
  // Local list state variable which will be used to store filtered list
  const [searchList, setSearchList] = useState(valueList);
  // Local list state variable which will be used to store filtered sub list
  const [filteredSublist, setFilteredSublist] = useState(subList);
  // state variable to view/hide sublist on parent value selection or removal
  const [viewSublist, setViewSublist] = useState(false);

  // useDidMountEffect(() => {
  //   setInputvalue(searchList[tapIndex]);
  //   onItemSelect(tapIndex, searchList[tapIndex]);
  // }, [tapIndex]);

  return (
    <>
      <div className="input-wrapper">
        <Input
          placeholder={placeholder}
          size={size ?? "large"}
          prefix={prefix ?? searchprefix}
          onChange={(e) => {
            // filtering parent list on the basis of text input
            setInputvalue(e.target.value);

            var templist = valueList.filter(function (str) {
              return str.toLowerCase().includes(e.target.value.toLowerCase());
            });

            if (e.target.value.length === 0) {
              setViewSublist(false);
            }
            // Filtering sublist on the basis of current selected vvalue from parent list
            // if (isSublist) {
            //   const newlist = sublistFilter(e.target.value.toLowerCase());
            //   setFilteredSublist(newlist);
            // }
            setSearchList(templist);
          }}
          value={inputvalue}
        />
      </div>

      <div className="list">
        <Row gutter={[8, 24]} justify="center">
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please select a value",
              },
            ]}
            name={name}
          >
            <Radio.Group size="large">
              {searchList.slice(0, 8).map((val, index) => {
                return isImageTile ? (
                  <Radio.Button
                    value={val}
                    key={index}
                    style={{ height: "max-content", margin: 5 }}
                    onClick={() => {
                      setInputvalue(val);
                    }}
                  >
                    <div className="image-tile">
                      <img src={MakerImage} alt={val} width="60px" />
                      <p>{val}</p>
                    </div>
                  </Radio.Button>
                ) : (
                  <Radio.Button
                    style={{ margin: 5 }}
                    value={val}
                    key={index}
                    onClick={() => {
                      setInputvalue(val);
                      // Filtering sublist on the basis of current selected value from parent list
                      if (isSublist) {
                        var templist = sublistFilter(val.toLowerCase());
                        setFilteredSublist(templist);
                        setViewSublist(true);
                      }
                    }}
                  >
                    {val}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </Form.Item>
        </Row>
      </div>
      {/* Sublist component */}
      {isSublist && viewSublist && (
        <div className="list">
          <Row gutter={[8, 24]} justify="center">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please select a value",
                },
              ]}
              name={sublistname}
            >
              <Radio.Group size="large">
                {filteredSublist.slice(0, 10).map((val, index) => {
                  return (
                    <Radio.Button
                      style={{ margin: 5 }}
                      value={val.rta_code}
                      key={index}
                    >
                      {val.rta_code}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </Row>
        </div>
      )}
    </>
  );
};

export default SearchAndList;

// Action button for going back in Car details tabs
export const PreviousButton = () => {
  const dispatch = useDispatch();
  return (
    <div
      className="next-btn"
      onClick={() => {
        dispatch(CarIndexActions.previousTab());
      }}
    >
      <Button type="primary">Previous</Button>
    </div>
  );
};

// Action buttons wrapper : displayed at the bottom part of Details tab
export const ActionButtons = () => {
  return (
    <div className="actions">
      <PreviousButton />
      <div className="next-btn">
        <Button type="primary" htmlType="submit">
          Next
        </Button>
      </div>
    </div>
  );
};
