import "./App.css";
import { Outlet, Route, Routes, Navigate } from "react-router";
import CarDetails from "./pages/CarInsurancePage/CarInsurance";
import NavBar from "./components/Homepage/navbar";
import Homepage from "./pages/Homepage";
import Login from "./pages/Authentication/Login";
import SignUp from "./pages/Authentication/Signup";
import AuthenticationWidget from "./pages/Authentication";
import InsuranceWidget from "./pages/Insurance";
import CarInsuranceResults from "./pages/CarInsurancePage/InsuranceResults";
import CarPraposal from "./pages/CarInsurancePage/CarPraposal";

function App() {
  const storageCardetails = sessionStorage.getItem("CarQueryParams");

  return (
    <div className="App">
      <header>
        <NavBar />
      </header>
      <Routes>
        {/* <Route path="/car/details" exact element={<CarDetails />}></Route> */}
        <Route path="/" exact element={<Homepage />}></Route>
        <Route
          path="/auth/*"
          exact
          element={
            <div>
              <Outlet />
            </div>
          }
        >
          <Route path="" exact element={<AuthenticationWidget />}></Route>
          <Route path="login" exact element={<Login />}></Route>
          <Route path="signup" exact element={<SignUp />}></Route>
        </Route>
        <Route
          path="/insurance/*"
          exact
          element={
            <div>
              <Outlet />
            </div>
          }
        >
          <Route path="" exact element={<InsuranceWidget />}></Route>
          <Route
            path="car/*"
            element={
              <div>
                <Outlet />
              </div>
            }
          >
            <Route path="" element={<Navigate to="details" />} />
            <Route path="details" exact element={<CarDetails />}></Route>
            <Route
              path="result"
              exact
              element={<CarInsuranceResults />}
            ></Route>
            <Route path="praposal" exact element={<CarPraposal />}></Route>
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
