import { Menu, Button, Drawer } from "antd";
import "./navbar.css";
import { Link } from "react-router-dom";
import { AlignRightOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { LogoImg } from "../../static";
import { useDispatch } from "react-redux";
import { CarIndexActions } from "../../store";
const SubMenu = Menu.SubMenu;

// Global navbar component
const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showDrawer, setShowDrawer] = useState(false);

  const showDrawerHandler = () => {
    setShowDrawer(true);
  };

  const onDrawerClose = () => {
    setShowDrawer(false);
  };

  return (
    <nav className="navbar">
      <div className="left-menu">
        <LeftMenu mode="horizontal" />
      </div>
      <div className="menu-container">
        <div
          className="nav-logo"
          style={location.pathname === "/" ? { visibility: "hidden" } : {}}
        >
          <img
            src={LogoImg}
            alt="logo"
            style={{ objectFit: "contain", width: "100%" }}
            onClick={() => {
              navigate("/");
            }}
          />
        </div>

        <div className="right-menu">
          <RightMenu mode="horizontal" />
        </div>
        <Button
          className="hamburger-btn"
          type="primary"
          onClick={showDrawerHandler}
        >
          <AlignRightOutlined size="medium" />
        </Button>
        {/* Drawer will be displayed only on small screens */}
        <Drawer
          title="FeAssurance"
          placement="right"
          className="menu-drawer"
          closable={true}
          onClose={onDrawerClose}
          visible={showDrawer}
          size="default"
        >
          <LeftMenu mode="vertical" />
          <RightMenu mode="vertical" />
        </Drawer>
      </div>
    </nav>
  );
};

export default NavBar;

// General menu
const LeftMenu = ({ mode }) => {
  const dispatch = useDispatch();

  return (
    <Menu mode={mode}>
      <Menu.Item key="home">
        <Link to="/">Home</Link>
      </Menu.Item>
      <Menu.Item key="about">
        <Link to="/">About</Link>
      </Menu.Item>

      <SubMenu title="Products" key="products">
        <Menu.Item key="car">
          <Link
            to="/insurance/car/details"
            onClick={() => {
              dispatch(CarIndexActions.resetTab());
            }}
          >
            Car Insurance
          </Link>
        </Menu.Item>
        <Menu.Item key="two-wheel">
          <Link to="/insurance/twowheeler/details">Two wheeler Insurance</Link>
        </Menu.Item>
        <Menu.Item key="travel">
          <Link to="/insurance/travel/details">Travel Insurance</Link>
        </Menu.Item>
        <Menu.Item key="commercial">
          <Link to="/insurance/commercial/details">Commercial Insurance</Link>
        </Menu.Item>
        <Menu.Item key="home-ins">
          <Link to="/insurance/home/details">Home Insurance</Link>
        </Menu.Item>
        <Menu.Item key="health">
          <Link to="/insurance/health/details">Health Insurance</Link>
        </Menu.Item>
      </SubMenu>

      <Menu.Item key="policypdf">
        <Link to="/">Policy PDF</Link>
      </Menu.Item>
      <Menu.Item key="insstatus">
        <Link to="/">Inspection status</Link>
      </Menu.Item>
      <Menu.Item key="contact">
        <Link to="/">Contact us</Link>
      </Menu.Item>
    </Menu>
  );
};

// Auth menu
const RightMenu = ({ mode }) => {
  const navigate = useNavigate();

  return (
    <Menu mode={mode}>
      <Menu.Item key="login">
        <Link to="/auth/login">Login</Link>
      </Menu.Item>
      <Menu.Item key="signup">
        <Button
          type="primary"
          onClick={() => {
            navigate("/auth/signup");
          }}
        >
          Sign up
        </Button>
      </Menu.Item>
    </Menu>
  );
};